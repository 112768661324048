<template>
	<div id="body-content">
		<!-- <my-header v-bind:title='title' v-bind:remark="remarkDec"></my-header> -->
		<MyNav v-bind:title="title" v-bind:remark="remarkDec"></MyNav>
		<div id="content" style="display: flex;">
			<div id="messageClasses"
				style="width: 10%;border:1px solid #000000;border-right-style: none;border-left-style: none;">
				<h3>日志分类</h3>
				<ul style="list-style-type: none;margin-left: 0px;padding-left: 0px;margin-top: 0px;">
					<li style="margin-bottom: 10px;border:none;border-bottom: 1px solid black;background-color: white;"
						v-for="eventClass in eventClasses" v-bind:key="eventClass.id">
						<a style="display: inline-block;color:#000000;cursor:pointer"
							v-on:click="ChangeEventClass(eventClass.id, eventClass.contact)">{{ eventClass.contact }}</a>
					</li>
				</ul>
			</div>
			<div id="messages" style="width:20%;border:1px solid #000000;border-right-style: none;">
				<h3 style="color:#000080;display:inline-block;">{{ currentEventClassName }}</h3>
				<a href="#" v-on:click="AddArticle()" style="color: #008000">添加</a>
				<div style="margin-bottom: 10px;">
					<label id="pageInfo">{{ pageInfo }}</label>
					<br>
					<button v-on:click="FirstPage()">第一页</button>
					<button v-on:click="PreviousPage()"> 上一页</button>
					<button v-on:click="NextPage()">下一页</button>
					<button v-on:click="LastPage()">最后一页</button>
				</div>
				<div id="messagesContainer">
					<ul style="list-style-type: none;margin-left: 0px;padding-left: 0px;margin-top: 0px;">
						<li style="margin-bottom: 5px;border:1px solid black;border-left: none;border-right:none;border-top:none;background-color: white;"
							v-for="message in messages" v-bind:key="message.ID">
							<a style="display: inline-block;color:#000000;cursor:pointer"
								v-on:click="EditArticle(message.id)">{{ message.title }}</a>
							<br>
							<label>{{ message.updateTime }}</label>
						</li>
					</ul>
				</div>
			</div>

			<div id="article" style="width: 70%;border:1px solid #000000;border-right-style: none;">
				<div id="articleTitle">
					<label>日志分类:</label>
					<select style="font-size: 12px;" id="eventClassesSelect">
						<option v-for="eventClass in eventClasses" v-bind:key="eventClass.id" v-bind:value="eventClass.id">
							{{ eventClass.contact }}
						</option>>
					</select>
					<label>标题</label>
					<input type="text" id="tbxTitle" size="50" v-model="currentMessageTitle" />
					<input type="button" v-on:click="SaveArticle()" value="保存" />
					<input type="button" v-on:click="DeleteArticle()" value="删除" />
				</div>
				<div id="articleContent">
					<!-- <textarea cols="100" rows="24" name="tbxContent" id="tbxContent"></textarea> -->
					<textarea name="tbxContent" id="tbxContent"></textarea>
				</div>
			</div>
			<div id="UIMessage" style="font-size: 24px;font-weight: bold;color:green;clear: both;"></div>
		</div>
	</div>
</template>

<script>
import SERVICE_BASE_URL from '../CommonJS/Config.js'
import $ from 'jquery'
import axios from 'axios'
import MyNav from '../components/MyNav.vue'
export default {
	data() {
		return {
			title: '小手冰凉的网站',
			username: '',
			remark: "",
			isLoggedIn: true,
			currentEventClassName: '',
			currentEventClassID: '',
			currentMessageID: '',
			currentMessageTitle: '',
			eventClasses: [],
			messages: [],
			pageInfo: '',
			totalPage: 1,
			article: '',
			editor: null
		}
	},
	components: {
		MyNav,
	},
	computed: {
		remarkDec: function () {
			if (this.remark && this.remark.length > 0) {
				return '--' + this.remark;
			}
			return '';
		},
		isAdmin: function () {
			return this.username === 'Administrator';
		}
	},
	mounted() {
		axios.defaults.headers.common['token'] = localStorage.getItem("token");
		let clientHeight = 0;
		if (document.compatMode == "BackCompat") {
			clientHeight = document.body.clientHeight;
		} else {
			clientHeight = document.documentElement.clientHeight;
		}
		this.editor = window.CKEDITOR.replace("tbxContent", {
			language: 'zh-cn', //简体中文
			skin: 'minimalist',
			height: clientHeight - $('#nav').height() - $('#tbxTitle').height() - 82
		});
		this.GetCurrentUserInfo();
		this.ShowEventClasses();
		this.autodivheight();
		window.onresize = () => {
			this.autodivheight();
		}
	},
	methods: {
		GetCurrentUserInfo() {
			axios.post(SERVICE_BASE_URL + "user/getCurrentUserInfo").then(
				(response) => {
					let data = response.data;
					document.title = data.result.username + "的日志";
					this.title = data.result.username;
					this.remark = data.result.remark;
					if (data.result.username.toString() != "Administrator") {
						$("#lblManageUsers").hide();
					} else {
						$("#lblManageUsers").show();
					}
				});
		},
		EditArticle(id) {
			this.currentMessageID = id;
			this.ShowArticle(id);
		},
		AddArticle() {
			this.currentMessageID = null;
			this.currentMessageTitle = '';
			$("#eventClassesSelect").val(this.currentEventClassID.toString());
			$('#tbxTitle').focus();
			window.CKEDITOR.instances.tbxContent.setData('', () => {
				window.CKEDITOR.instances.tbxContent.document.$.body.onkeydown = (ev) => {
					this.CheckHotKey(ev); //可以用来监测你的按键
				};
				window.CKEDITOR.instances.tbxContent.focus();
				var range = window.CKEDITOR.instances.tbxContent.createRange();
				range.moveToElementEditEnd(window.CKEDITOR.instances.tbxContent.editable());
				range.select();
				range.scrollIntoView();
				$('#tbxTitle').focus();
			});
		},
		ShowMessages(data) {
			this.messages = data.records;
			this.pageInfo = data.current + "/" + data.pages + " 共" + data.total + "篇";
			this.totalPage = data.pages;
		},
		ShowCurrentMessages() {
			if (localStorage.getItem("stage") == null) {
				return;
			}
			if (localStorage.getItem("page") == null) {
				localStorage.setItem("page", 1);
			}
			axios.get(SERVICE_BASE_URL + "messages/getMessages", {
				params: {
					stage: localStorage.getItem("stage"),
					pageNumber: localStorage.getItem("page"),
					rowsPerPage: 10
				}
			}).then(
				(response) => {
					this.ShowMessages(response.data.result);
				});
		},
		NextPage() {
			if (localStorage.getItem("page") < this.totalPage) {
				localStorage.setItem("page", Number(localStorage.getItem("page")) + 1);
				this.ShowCurrentMessages();
			}
		},
		PreviousPage() {
			if (localStorage.getItem("page") > 1) {
				localStorage.setItem("page", Number(localStorage.getItem("page")) - 1);
				this.ShowCurrentMessages();
			}
		},
		FirstPage() {
			localStorage.setItem("page", 1);
			this.ShowCurrentMessages();
		},
		LastPage() {
			localStorage.setItem("page", this.totalPage);
			this.ShowCurrentMessages();
		},
		ShowEventClasses() {
			axios.get(SERVICE_BASE_URL + "eventclass/getMyEventClasses").then(
				(response) => {
					this.eventClasses = response.data.result;
					if (this.eventClasses.length > 0) {
						if (localStorage.getItem("stage") == null) {
							localStorage.setItem("stage", this.eventClasses[0].id);
							this.currentEventClassID = this.eventClasses[0].id;
							this.currentEventClassName = this.eventClasses[0].contact;
						} else {
							for (let i = 0; i < this.eventClasses.length; i++) {
								if (this.eventClasses[i].id == localStorage.getItem("stage")) {
									this.currentEventClassID = this.eventClasses[i].id;
									this.currentEventClassName = this.eventClasses[i].contact;
								}
							}
						}
						this.ShowCurrentMessages();
					}
				});
		},
		ChangeEventClass(eventClassID, eventClassContact) {
			this.currentEventClassID = eventClassID;
			this.currentEventClassName = eventClassContact;
			localStorage.setItem("stage", eventClassID);
			localStorage.setItem("page", 1);
			this.ShowCurrentMessages();
		},
		DeleteArticle() {
			if (this.currentMessageID == null) {
				return;
			}
			if (confirm("是否删除此文章?") == false) {
				return;
			}
			let postParam = {
				"id": this.currentMessageID
			};
			axios.post(SERVICE_BASE_URL + "messages/delMessagesById", postParam).then(
				(response) => {
					let data = response.data.result;
					if (data) {
						this.currentMessageID = '';
						this.currentMessageTitle = '';
						this.article = '';
						this.ShowCurrentMessages();
					} else {
						alert("删除失败");
					}
				});
		},
		ShowArticle(id) {
			axios.get(SERVICE_BASE_URL + "messages/getMessagesById", {
				params: {
					id: id
				}
			}).then(
				(response) => {
					let data = response.data.result;
					this.currentMessageTitle = data.title;
					this.currentEventClassID = data.stage;
					$("#eventClassesSelect").val(data.stage.toString());
					window.CKEDITOR.instances.tbxContent.setData(data.content, () => {
						window.CKEDITOR.instances.tbxContent.document.$.body.onkeydown = (ev) => {
							this.CheckHotKey(ev); //可以用来监测你的按键
						};
						window.CKEDITOR.instances.tbxContent.focus();
						var range = window.CKEDITOR.instances.tbxContent.createRange();
						range.moveToElementEditEnd(window.CKEDITOR.instances.tbxContent.editable());
						range.select();
						range.scrollIntoView();
					});
				});
		},
		SaveArticle() {
			var data = window.CKEDITOR.instances.tbxContent.getData();
			if (this.currentMessageTitle.length < 1 || data.length < 1) {
				alert("标题或内容不能为空");
				return;
			}
			axios.post(SERVICE_BASE_URL + "messages/saveMessages", {
				title: this.currentMessageTitle,
				content: data,
				stage: $("#eventClassesSelect")[0].value,
				id: this.currentMessageID,
			}).then(
				(response) => {
					if (response.data.result) {
						this.showUIMessageCenter("保存成功");
						this.ShowCurrentMessages();
						if (this.currentMessageID == null) {
							this.currentMessageID = response.data.result;
						}
					} else {
						this.showUIMessageCenter("更新失败");
					}
				});
		},
		CheckHotKey(e) {

			e = e ? e : window.event;

			var keyCode = e.which ? e.which : e.keyCode;
			if (e.ctrlKey && keyCode == 83) {
				this.SaveArticle();
				this.ShutdownEvent(e);
			}
		},
		ShutdownEvent(e) {
			// Prevent the default browser action (W3C)
			if (e && e.preventDefault)
				e.preventDefault();
			else
				// A shortcut for stoping the browser action in IE
				window.event.returnValue = false;
		},
		showUIMessageCenter(uiMessage) {
			var divName = "#UIMessage";
			$(divName).html("<p class=\"text-info\">" + uiMessage + "</p>");
			$(divName).css({
				width: "20%",
			});
			var top = ($(window).height() - $(divName).height()) / 2;
			var left = ($(window).width() - $(divName).width()) / 2;
			var scrollTop = $(document).scrollTop();
			var scrollLeft = $(document).scrollLeft();
			$(divName).css({
				position: 'absolute',
				'top': top + scrollTop,
				left: left + scrollLeft
			});
			$(divName).show();
			$(divName).fadeIn(50);
			$(divName).fadeOut(1000);
		},
		Search() {
			if ($("#tbxKeyword")[0].value.length > 0) {
				location.href = "search.html?keyword=" + escape($("#tbxKeyword")[0].value);
			}
		},
		Logout() {
			localStorage.removeItem("token");
			localStorage.removeItem("page");
			localStorage.removeItem("stage");
			this.$router.replace('/login');
		},
		autodivheight() { //函数：获取尺寸
			//获取浏览器窗口高度
			var winHeight = 0;
			if (window.innerHeight)
				winHeight = window.innerHeight;
			else if ((document.body) && (document.body.clientHeight))
				winHeight = document.body.clientHeight;
			//通过深入Document内部对body进行检测，获取浏览器窗口高度
			if (document.documentElement && document.documentElement.clientHeight)
				winHeight = document.documentElement.clientHeight;
			//DIV高度为浏览器窗口的高度
			var navHeight = document.getElementById("nav").offsetHeight;
			var contentHeight = winHeight - navHeight;
			document.getElementById("content").style.height = contentHeight + "px";
			document.getElementById("messageClasses").style.height = contentHeight + "px";
			document.getElementById("messages").style.height = contentHeight + "px";
			document.getElementById("article").style.height = contentHeight + "px";
		}
	}
}
</script>

<style scoped>
#content {
	background-image: url("../images/Fiesta\ Bkgrd.jpg");

	justify-content: center;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
</style>