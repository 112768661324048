<template>
	<div id="head" style="display: flex;">
		<img id="logo" alt="城堡" src="../images/heidelb.jpg" />
		<h1>{{title}}</h1>
		<h2>{{remark}}</h2>

	</div>
</template>

<script>
	export default {
		name: 'MyNav',
		props: {
			title: String,
			remark: String
		}
	}
</script>

<style>
</style>