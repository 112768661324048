import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import DefaultLogin from './views/DefaultLogin.vue'
import MainEditor from './views/MainEditor.vue'
import MainEditorTinyMce from './views/MainEditorTinyMce.vue'
import CKEditor from 'ckeditor4-vue';

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(CKEditor)

const routes=[
	{path:'/',component:DefaultLogin},
	{path:'/login',component:DefaultLogin},
	{path:'/main',component:MainEditor},
	{path:'/mainTinyMce',component:MainEditorTinyMce}
]

const router =new VueRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  routes:routes, // `routes: routes` 的缩写
})


const app=new Vue({
  render: h => h(App),
  router:router
})
app.$mount('#app')
