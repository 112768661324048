<template>
	<div id="app">
		<router-view>
			<DefaultLogin></DefaultLogin>
		</router-view>
	</div>
</template>

<script>
	import DefaultLogin from './views/DefaultLogin.vue'

	export default {
		name: 'App',
		components: {
			DefaultLogin
		}
	}
</script>

<style>
	body {
		margin: 0px;
		background-image: url(./images/stripes_vert_baby.jpg);
	}

	#head {
		justify-content: left;
		align-items: center;
		height: 141px;
		margin: 0px;
		background-color: transparent;
	}

	#logo {
		width: 242px;
		height: 141px;
	}

	#nav li {
		margin-right: 20px;
	}

	

	#foot {
		background-color: transparent;
		text-align: center;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin-top: 0px;
	}
</style>