<template>
	<div id="nav" style="height: 35px;">
		<img style="display:inline-block;width:35px;height:35px;float:left" id="logo" alt="城堡"
			src="../images/heidelb.jpg" />
		<div style="display: inline-block;height: 35px;float: left;line-height: 35px;">{{ title }}{{ remark }}</div>
		<div style="display:inline-block;float: right;margin-right: 30px;height: 35px;line-height: 35px;">
			<a id="lbtnLogout" href="#" v-on:click="Logout()">登出</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MyNav',
	props: {
		title: String,
		remark: String
	},
	methods: {
		Logout() {
			this.$parent.Logout()
		}
	}
}
</script>

<style></style>