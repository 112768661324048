<template>
	<div id="body-content">
		<my-header v-bind:title='title' v-bind:remark="remarkDec" v-bind:is-loggedin="false" v-bind:is-admin="false">
		</my-header>
		<div id="content">
			<form>
				<fieldset>
					<legend>用户登录</legend>
					<table>
						<tr>
							<td>
								<label for="tbxUsername">用户名</label>
							</td>
							<td>
								<input type="text" v-model="username" id="tbxUsername" v-on:keyup="clearWarning"
									v-focus />
							</td>
						</tr>
						<tr>
							<td>
								<label for="tbxPassword">密码</label>
							</td>
							<td>
								<input v-model="password" type="password" id="tbxPassword" v-on:keyup="clearWarning"
									v-on:keyup.enter="checkUserPass" />
							</td>
						</tr>
						<tr>
							<td></td>
							<td>
								<button type="button" v-on:click="checkUserPass">登录</button>
								<a style="margin-left: 20px;" href="AddNewUser.html">注册新用户</a>
							</td>
						</tr>
					</table>
				</fieldset>
				<br>
				<label id="lblPassWaring" style="color: #FF0000">{{warnning}}</label>
			</form>
		</div>
		<my-footer></my-footer>
	</div>
</template>

<script>
	import SERVICE_BASE_URL from '../CommonJS/Config.js'
	import $ from 'jquery'
	import sha256 from 'sha256'
	import MyHeader from '../components/MyHeader.vue'
	import MyFooter from '../components/MyFooter.vue'
	export default {
		name: 'DefaultLogin',
		components: {
			MyHeader,
			MyFooter
		},
		data() {
			return {
				title: '小手冰凉的网站',
				username: '',
				password: '',
				remark: '',
				warnning: '',
			}
		},
		computed: {
			encryptedPassword: function() {
				let ep = sha256(this.password);
				return ep;
			},
			remarkDec: function() {
				if (this.remark && this.remark.length > 0) {
					return '--' + this.remark;
				}
				return '';
			}
		},
		methods: {
			clearWarning: function() {
				this.warnning = '';
			},
			checkUserPass: function() {
				$.ajaxSetup({
					xhrFields: {
						withCredentials: true
					},
				});
				let vueThis = this;
				$.post(SERVICE_BASE_URL+"user/login", {
						username: this.username,
						password: this.encryptedPassword
					},
					function(data) {
						if (data.success == true) {
							localStorage.setItem('token', data.result);
							vueThis.$router.replace('/main');
						} else {
							vueThis.warnning = "用户名或密码不正确";
						}
					});
			}
		},
		directives: {
			focus: {
				// 指令的定义
				inserted: function(el) {
					el.focus()
				}
			}
		}
	}
</script>

<style scoped>
	form {
		background-color: transparent;
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: x-large;
	}
	
	legend {
		font-size: x-large;
		font-weight: bold;
	}
	
	label {
		display: inline-block;
		width: 80px;
		text-align: right;
		margin-top: 20px;
		margin-right: 10px;
		margin-bottom: 10px;
	}
	
	input {
		font-size: x-large;
	}
	
	#buttonHolder {
		display: inline-block;
		width: 177px;
		text-align: right;
		margin-right: 10px;
		margin-bottom: 10px;
		margin-top: 20px;
	}
	
	button {
		background-color: transparent;
		font-size: x-large;
		display: inline-block;
		width: 80px;
		margin-left: 0px;
		margin-right: 0px;
	}
	
	#lblPassWaring {
		width: 300px;
		display: block;
		margin: 0px;
		padding: 0px;
		border: 0px;
	}

	#content {
	background-image: url("../images/Fiesta\ Bkgrd.jpg");
	display: flex;
	justify-content: center;
}
	
</style>